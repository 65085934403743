// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-consortium-js": () => import("./../../../src/pages/consortium.js" /* webpackChunkName: "component---src-pages-consortium-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-research-projects-js": () => import("./../../../src/pages/research-projects.js" /* webpackChunkName: "component---src-pages-research-projects-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-upcoming-event-js": () => import("./../../../src/pages/upcoming-event.js" /* webpackChunkName: "component---src-pages-upcoming-event-js" */),
  "component---src-pages-view-components-js": () => import("./../../../src/pages/ViewComponents.js" /* webpackChunkName: "component---src-pages-view-components-js" */),
  "component---src-templates-past-events-js": () => import("./../../../src/templates/past-events.js" /* webpackChunkName: "component---src-templates-past-events-js" */)
}

